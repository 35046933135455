import { PAGE_SIZE } from "@/utils/constants";
import { PLACE_SCHEDULE_TYPE } from "@/utils/constantLists";
import axios from "axios";

export default class EnquiryReservation {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.enquiryReservationToken = "";
    this.enquiryReservationCode = "";
    this.addedByUserToken = "";
    this.addedByUserNameCurrent = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.clientToken = "";
    this.clientCode = "";
    this.clientNameCurrent = "";
    this.clientPhone = "";
    this.clientPhoneWithCC = "";
    this.clientImagePath = "";
    this.activityToken = "";
    this.activityNameCurrent = "";
    this.activityImagePath = "";
    this.placeToken = "";
    this.placePartToken = "";
    this.placeCode = "";
    this.placeImagePath = "";
    this.placeNameCurrent = "";
    this.placePhone1 = "";
    this.placePhone1WithCC = "";
    this.placePhone2 = "";
    this.placePhone2WithCC = "";
    this.placePhone3 = "";
    this.placePhone3WithCC = "";
    this.placePhone4 = "";
    this.placePhone4WithCC = "";
    this.mapLink = "";
    this.placeLatitude = "";
    this.placeLongitude = "";
    this.placeAddressCurrent = "";
    this.enquiryReservationNameCurrent = "";
    this.enquiryReservationNameAr = "";
    this.enquiryReservationNameEn = "";
    this.enquiryReservationNameUnd = "";
    this.enquiryReservationDescriptionCurrent = "";
    this.enquiryReservationDescriptionAr = "";
    this.enquiryReservationDescriptionEn = "";
    this.enquiryReservationDescriptionUnd = "";
    this.totalPrice = "";
    this.enquiryReservationNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.reservationsStatus = "";
    this.enquiryReservationArchiveStatus = "";
    this.saveEnquiryReservation = true;
    this.enquiryReservationsSchedulesData = [];
  }
  fillData(data) {
    this.enquiryReservationToken = data.enquiryReservationToken;
    this.enquiryReservationCode = data.enquiryReservationCode;
    this.addedByUserToken = data.addedByUserToken;
    this.addedByUserNameCurrent = data.addedByUserNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.clientToken = data.clientToken;
    this.clientCode = data.clientCode;
    this.clientNameCurrent = data.clientNameCurrent;
    this.clientPhone = data.clientPhone;
    this.clientPhoneWithCC = data.clientPhoneWithCC;
    this.clientImagePath = data.clientImagePath;
    this.activityToken = data.activityToken;
    this.activityNameCurrent = data.activityNameCurrent;
    this.activityImagePath = data.activityImagePath;
    this.placeToken = data.placeToken;
    this.placePartToken = data.placePartToken;
    this.placeCode = data.placeCode;
    this.placeImagePath = data.placeImagePath;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placePhone1 = data.placePhone1;
    this.placePhone1WithCC = data.placePhone1WithCC;
    this.placePhone2 = data.placePhone2;
    this.placePhone2WithCC = data.placePhone2WithCC;
    this.placePhone3 = data.placePhone3;
    this.placePhone3WithCC = data.placePhone3WithCC;
    this.placePhone4 = data.placePhone4;
    this.placePhone4WithCC = data.placePhone4WithCC;
    this.mapLink = data.mapLink;
    this.placeLatitude = data.placeLatitude;
    this.placeLongitude = data.placeLongitude;
    this.placeAddressCurrent = data.placeAddressCurrent;
    this.enquiryReservationNameCurrent = data.enquiryReservationNameCurrent;
    this.enquiryReservationNameAr = data.enquiryReservationNameAr;
    this.enquiryReservationNameEn = data.enquiryReservationNameEn;
    this.enquiryReservationNameUnd = data.enquiryReservationNameUnd;
    this.enquiryReservationDescriptionCurrent =
      data.enquiryReservationDescriptionCurrent;
    this.enquiryReservationDescriptionAr = data.enquiryReservationDescriptionAr;
    this.enquiryReservationDescriptionEn = data.enquiryReservationDescriptionEn;
    this.enquiryReservationDescriptionUnd =
      data.enquiryReservationDescriptionUnd;
    this.totalPrice = data.totalPrice;
    this.enquiryReservationNotes = data.enquiryReservationNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.reservationsStatus = data.reservationsStatus;
    this.enquiryReservationArchiveStatus = data.enquiryReservationArchiveStatus;
    this.saveEnquiryReservation = data.saveEnquiryReservation;
    this.enquiryReservationsSchedulesData =
      data.enquiryReservationsSchedulesData;
  }

  async getAllEnquiryReservations(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    try {
      let response = await axios.get(
        `/EnquiryReservations/GetAllEnquiryReservations/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=false&includeStatus=true&searchByReservationStatus=true&reservationsStatus=false&activityToken=${filterData.activityToken}&placeToken=${filterData.placeToken}&clientToken=${filterData.clientToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEnquiryReservationsDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EnquiryReservations/GetEnquiryReservationsDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&enquiryReservationToken=${this.enquiryReservationToken}&includeStatus=true`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEnquiryReservations(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EnquiryReservations/GetDialogOfEnquiryReservations?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async getAppointmentsAvailableForReservation(
    language,
    userAuthorizeToken,
    dateTimeFrom,
    dateTimeTo
  ) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      clientToken: this.clientToken,
      placeToken: this.placeToken,
      // enquiryReservationsSchedulesData: this.enquiryReservationsSchedulesData,
      enquiryReservationsSchedulesData: {
        dateTimeFrom: dateTimeFrom,
        dateTimeTo: dateTimeTo,
        placeScheduleTypeToken: PLACE_SCHEDULE_TYPE.fixed,
      },
    };

    try {
      let response = await axios.post(
        `/EnquiryReservations/GetAppointmentsAvailableForReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async addEnquiryReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      clientToken: this.clientToken,
      placeToken: this.placeToken,
      enquiryReservationDescriptionAr: this.enquiryReservationDescriptionAr,
      enquiryReservationDescriptionEn: this.enquiryReservationDescriptionEn,
      enquiryReservationDescriptionUnd: this.enquiryReservationDescriptionUnd,
      enquiryReservationNotes: this.enquiryReservationNotes,
      saveEnquiryReservation: this.saveEnquiryReservation,
      enquiryReservationsSchedulesData: this.enquiryReservationsSchedulesData,
    };

    try {
      let response = await axios.post(
        `/EnquiryReservations/AddEnquiryReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async finalEnquiryReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      enquiryReservationToken: this.enquiryReservationToken,
    };

    try {
      let response = await axios.post(
        `/EnquiryReservations/FinalEnquiryReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
