var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":'placeToken',"value":_vm.enquiryReservationData.placeToken,"options":_vm.placeTokenOptions,"title":_vm.$t('places.select'),"imgName":'places.svg'},on:{"changeValue":function($event){_vm.enquiryReservationData.placeToken = $event;
            _vm.placeTokenChanged();}}}),_c('DatePicker',{attrs:{"className":'col-md-12 col-lg-4',"id":'dateFrom',"value":_vm.dateFrom,"title":_vm.$t('reservations.date'),"language":_vm.language},on:{"changeValue":function($event){_vm.dateFrom = $event}}}),_c('TimePicker',{attrs:{"className":'col-md-6 col-lg-4',"id":'timeFrom',"value":_vm.timeFrom,"title":_vm.$t('timeFrom'),"language":_vm.language},on:{"changeValue":function($event){_vm.timeFrom = $event}}}),_c('TimePicker',{attrs:{"className":'col-md-6 col-lg-4',"id":'timeTo',"value":_vm.timeTo,"title":_vm.$t('timeTo'),"language":_vm.language},on:{"changeValue":function($event){_vm.timeTo = $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit","disabled":_vm.searchIsDisabled},on:{"click":function($event){$event.preventDefault();return _vm.getAppointmentsAvailableForReservation()}}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])]),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[(!_vm.isClient)?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'clientToken',"value":_vm.enquiryReservationData.clientToken,"options":_vm.clientTokenOptions,"title":_vm.$t('selectClient'),"imgName":'clients.svg'},on:{"changeValue":function($event){_vm.enquiryReservationData.clientToken = $event}}}):_vm._e()],1),(_vm.exceptionMsgAvailableAppointments)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsgAvailableAppointments,"showImageStatus":false}}):_vm._e(),(
          _vm.availableAppointments !== undefined &&
          _vm.availableAppointments.length !== 0
        )?_c('div',[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allSelected),expression:"allSelected"}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"selectAll"},domProps:{"checked":Array.isArray(_vm.allSelected)?_vm._i(_vm.allSelected,null)>-1:(_vm.allSelected)},on:{"click":_vm.selectAll,"change":function($event){var $$a=_vm.allSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allSelected=$$c}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"selectAll"}},[_vm._v(_vm._s(_vm.$t("selectAll")))])]),_vm._l((_vm.availableAppointments),function(item,index){return _c('div',{key:index,staticClass:"row"},[(item.statusReservationApproved == true)?_c('lable',[_vm._v(" "+_vm._s(item.appointmentNameCurrent)+" ")]):_c('div',{staticClass:"col-lg-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                _vm.enquiryReservationData.enquiryReservationsSchedulesData
              ),expression:"\n                enquiryReservationData.enquiryReservationsSchedulesData\n              "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":("item" + index)},domProps:{"value":item,"checked":Array.isArray(
                _vm.enquiryReservationData.enquiryReservationsSchedulesData
              )?_vm._i(
                _vm.enquiryReservationData.enquiryReservationsSchedulesData
              ,item)>-1:(
                _vm.enquiryReservationData.enquiryReservationsSchedulesData
              )},on:{"click":_vm.select,"change":function($event){var $$a=
                _vm.enquiryReservationData.enquiryReservationsSchedulesData
              ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.enquiryReservationData, "enquiryReservationsSchedulesData", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.enquiryReservationData, "enquiryReservationsSchedulesData", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.enquiryReservationData, "enquiryReservationsSchedulesData", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":("item" + index)}},[_vm._v(" "+_vm._s(item.appointmentNameCurrent)+" ")])])],1)})],2):_vm._e()],1),_c('div',{staticClass:"form-footer"},[(
          _vm.availableAppointments !== undefined &&
          _vm.availableAppointments.length !== 0
        )?_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit","disabled":_vm.addIsDisabled},on:{"click":function($event){$event.preventDefault();return _vm.addEnquiryReservation()}}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]):_vm._e(),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":"/reservations/enquiry-reservations"}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }